import { client } from "../../../../core/service/client";

const emailInput = document.querySelector("#email");
const passwordInput = document.querySelector("#password");
const button = document.querySelector("#login_btn");

export async function loginUseCase(e) {
  e.preventDefault();

  if (verifyIfInputIsValid(emailInput) && verifyIfInputIsValid(passwordInput)) {
    const email = emailInput.value;
    const password = passwordInput.value;
    button.classList.toggle("isLoading");
    try {
      const response = await client.post(`/`, { email, password });
      const token = response.data.token;
      const city = response.data.user.city;
      const role = response.data.user.role;
      localStorage.setItem("token", token);
      localStorage.setItem("city", city);
      localStorage.setItem("role", role);
      window.location.replace("/module/home/home.html");
    } catch (err) {
      Toastify({
        text:
          err.response.data.message ??
          "Estamos fora do ar, tente novamente mais tarde",
        className: "warning",
        style: {
          background: "#ef233c",
          color: "white",
        },
      }).showToast();
    } finally {
      button.classList.toggle("isLoading");
    }
  }
}

function verifyIfInputIsValid(input) {
  const value = input.value;

  if (value.trim() === "") {
    return false;
  } else if (value < 8) {
    Toastify({
      text: "This is a toast",
      className: "info",
      style: {
        background: "linear-gradient(to right, #00b09b, #96c93d)",
      },
    }).showToast();
    return false;
  }

  return true;
}
